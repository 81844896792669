/***
 * Lyle F. Chamarette
 * 27th Jan 2023
 *
 * Yeah, I know... Lots of room for optimizations.
 * Keep in mind this is a POC.
 *
 * London, Ontario
 *
 * CURRENT WEATHER
 * 4:32 PM
 * Cloudy
 * -2°C
 * Cloudy
 * FEELS LIKE
 * -10°
 * The skies will be cloudy. The high will be -2° and it will be blustery.
 */

import { useEffect, useState } from 'react'
import {GiCard2Hearts, GiCard3Hearts, GiCard4Hearts, GiCard5Hearts, GiCard6Hearts, GiCard7Hearts, GiCard8Hearts, GiCard9Hearts, GiCard10Hearts, GiCardJackHearts, GiCardQueenHearts, GiCardKingHearts, GiCardAceHearts, GiCard2Clubs, GiCard3Clubs, GiCard4Clubs, GiCard5Clubs, GiCard6Clubs, GiCard7Clubs, GiCard8Clubs, GiCard9Clubs, GiCard10Clubs, GiCardJackClubs, GiCardQueenClubs, GiCardKingClubs, GiCardAceClubs, GiCard2Diamonds, GiCard3Diamonds, GiCard4Diamonds, GiCard5Diamonds, GiCard6Diamonds, GiCard7Diamonds, GiCard8Diamonds, GiCard9Diamonds, GiCard10Diamonds, GiCardJackDiamonds, GiCardQueenDiamonds, GiCardKingDiamonds, GiCardAceDiamonds, GiCard2Spades, GiCard3Spades, GiCard4Spades, GiCard5Spades, GiCard6Spades, GiCard7Spades, GiCard8Spades, GiCard9Spades, GiCard10Spades, GiCardJackSpades, GiCardQueenSpades, GiCardKingSpades, GiCardAceSpades} from 'react-icons/gi'
import './MindRead.css'
import imgUptime from "./img/uptime.gif";
import imgWin from "./img/mindblown.gif";

const cards=[
    { 'icon':GiCard2Hearts, 'suit': 'Hearts', 'rank':'2'},
    { 'icon':GiCard3Hearts, 'suit': 'Hearts', 'rank':'3'},
    { 'icon':GiCard4Hearts, 'suit': 'Hearts', 'rank':'4'},
    { 'icon':GiCard5Hearts, 'suit': 'Hearts', 'rank':'5'},
    { 'icon':GiCard6Hearts, 'suit': 'Hearts', 'rank':'6'},
    { 'icon':GiCard7Hearts, 'suit': 'Hearts', 'rank':'7'},
    { 'icon':GiCard8Hearts, 'suit': 'Hearts', 'rank':'8'},
    { 'icon':GiCard9Hearts, 'suit': 'Hearts', 'rank':'9'},
    { 'icon':GiCard10Hearts, 'suit': 'Hearts', 'rank':'10'},
    { 'icon':GiCardJackHearts, 'suit': 'Hearts', 'rank':'Jack'},
    { 'icon':GiCardQueenHearts, 'suit': 'Hearts', 'rank':'Queen'},
    { 'icon':GiCardKingHearts, 'suit': 'Hearts', 'rank':'King'},
    { 'icon':GiCardAceHearts, 'suit': 'Hearts', 'rank':'Ace'},
    { 'icon':GiCard2Clubs, 'suit': 'Clubs', 'rank':'2'},
    { 'icon':GiCard3Clubs, 'suit': 'Clubs', 'rank':'3'},
    { 'icon':GiCard4Clubs, 'suit': 'Clubs', 'rank':'4'},
    { 'icon':GiCard5Clubs, 'suit': 'Clubs', 'rank':'5'},
    { 'icon':GiCard6Clubs, 'suit': 'Clubs', 'rank':'6'},
    { 'icon':GiCard7Clubs, 'suit': 'Clubs', 'rank':'7'},
    { 'icon':GiCard8Clubs, 'suit': 'Clubs', 'rank':'8'},
    { 'icon':GiCard9Clubs, 'suit': 'Clubs', 'rank':'9'},
    { 'icon':GiCard10Clubs, 'suit': 'Clubs', 'rank':'10'},
    { 'icon':GiCardJackClubs, 'suit': 'Clubs', 'rank':'Jack'},
    { 'icon':GiCardQueenClubs, 'suit': 'Clubs', 'rank':'Queen'},
    { 'icon':GiCardKingClubs, 'suit': 'Clubs', 'rank':'King'},
    { 'icon':GiCardAceClubs, 'suit': 'Clubs', 'rank':'Ace'},
    { 'icon':GiCard2Diamonds, 'suit': 'Diamonds', 'rank':'2'},
    { 'icon':GiCard3Diamonds, 'suit': 'Diamonds', 'rank':'3'},
    { 'icon':GiCard4Diamonds, 'suit': 'Diamonds', 'rank':'4'},
    { 'icon':GiCard5Diamonds, 'suit': 'Diamonds', 'rank':'5'},
    { 'icon':GiCard6Diamonds, 'suit': 'Diamonds', 'rank':'6'},
    { 'icon':GiCard7Diamonds, 'suit': 'Diamonds', 'rank':'7'},
    { 'icon':GiCard8Diamonds, 'suit': 'Diamonds', 'rank':'8'},
    { 'icon':GiCard9Diamonds, 'suit': 'Diamonds', 'rank':'9'},
    { 'icon':GiCard10Diamonds, 'suit': 'Diamonds', 'rank':'10'},
    { 'icon':GiCardJackDiamonds, 'suit': 'Diamonds', 'rank':'Jack'},
    { 'icon':GiCardQueenDiamonds, 'suit': 'Diamonds', 'rank':'Queen'},
    { 'icon':GiCardKingDiamonds, 'suit': 'Diamonds', 'rank':'King'},
    { 'icon':GiCardAceDiamonds, 'suit': 'Diamonds', 'rank':'Ace'},
    { 'icon':GiCard2Spades, 'suit': 'Spades', 'rank':'2'},
    { 'icon':GiCard3Spades, 'suit': 'Spades', 'rank':'3'},
    { 'icon':GiCard4Spades, 'suit': 'Spades', 'rank':'4'},
    { 'icon':GiCard5Spades, 'suit': 'Spades', 'rank':'5'},
    { 'icon':GiCard6Spades, 'suit': 'Spades', 'rank':'6'},
    { 'icon':GiCard7Spades, 'suit': 'Spades', 'rank':'7'},
    { 'icon':GiCard8Spades, 'suit': 'Spades', 'rank':'8'},
    { 'icon':GiCard9Spades, 'suit': 'Spades', 'rank':'9'},
    { 'icon':GiCard10Spades, 'suit': 'Spades', 'rank':'10'},
    { 'icon':GiCardJackSpades, 'suit': 'Spades', 'rank':'Jack'},
    { 'icon':GiCardQueenSpades, 'suit': 'Spades', 'rank':'Queen'},
    { 'icon':GiCardKingSpades, 'suit': 'Spades', 'rank':'King'},
    { 'icon':GiCardAceSpades, 'suit': 'Spades', 'rank':'Ace'}
];
const shuffle=(array)=>{
    let currentIndex = array.length,  randomIndex;
    if (!currentIndex) return array;
    const arrCopy = [];
    array.forEach( (c, idx)=> {
        arrCopy.push({
            icon: c.icon,
            suit: c.suit,
            rank: c.rank,
            myIdx: idx
        })
    });
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [arrCopy[currentIndex], arrCopy[randomIndex]] = [arrCopy[randomIndex], arrCopy[currentIndex]];
    }
    return arrCopy;
}

const setWinnerOnRoot=()=>{
    const r = window.document.getElementById("root");
    if (r) r.classList.add("winner");
}
const resetWinnerOnRoot=()=>{
    const r = window.document.getElementById("root");
    if (r) r.classList.remove("winner");
}

const gameStates = ['round1', 'round2', 'round3', 'winner'];

const gameCaptions = [];
gameCaptions['round1'] = <><p>Pick a card in your mind.</p><p>Tell me in which group it is...</p></>
gameCaptions['round2'] = <><p>Oooooh! I'm getting a reading...</p><p>Same card, select the group...</p></>
gameCaptions['round3'] = <><p>I'm almost there!</p><p>Last time, same card, select the group...</p></>
gameCaptions['winner'] = <><p>Here is your card!</p></>


export default function MindRead(){

    const [col1, setCol1] = useState([]);
    const [col2, setCol2] = useState([]);
    const [col3, setCol3] = useState([]);

    const [vuCol1, setVuCol1] = useState([]);
    const [vuCol2, setVuCol2] = useState([]);
    const [vuCol3, setVuCol3] = useState([]);
    const [winningGroup, setWinningGroup] = useState(0);

    const [currentGameState, setCurrentGameState] = useState(0);
    const [semaphoreDealing, setSemaphoreDealing] = useState(0);
    const gotoNextGameState = ()=>{
        let c = currentGameState;
        if (c < (gameStates.length -1))
            c = c + 1;
        else
            c = 0;
        setCurrentGameState(c);
    }

    const shuffleDeck=()=>{
        const shuffledDeck=shuffle(cards);

        const c1 = shuffledDeck.slice(0, 7);
        const c2 = shuffledDeck.slice(7, 14);
        const c3 = shuffledDeck.slice(14, 21)

        setCol1(c1);
        setCol2(c2);
        setCol3(c3);

        setVuCol1(shuffle(c1));
        setVuCol2(shuffle(c2));
        setVuCol3(shuffle(c3));

        setCurrentGameState(0);
        setWinningGroup(0);
        resetWinnerOnRoot();
    }

    const dealHands=()=>{
        setTimeout( ()=>{window.document.getElementById("btn-dealer").click()}, 1 );
    }

    useEffect(()=>{
        // console.log("in useEffect=()");
        const txtCurrentGameStateName = gameStates[currentGameState];
        if (txtCurrentGameStateName==='round1'){
            shuffleDeck();
            dealHands();
        }
    }, [currentGameState])

    const recombineAndDealHands=(idxSelectedHand)=>{
        let recombinedHands = [];
        switch(idxSelectedHand)
        {
            case 1: recombinedHands = col3.concat(col1.concat(col2)); break;
            case 2: recombinedHands = col1.concat(col2.concat(col3)); break;
            case 3: recombinedHands = col2.concat(col3.concat(col1)); break;
            default: ;
        }

        const [c1, c2, c3] = [[], [], []];
        recombinedHands.forEach( (card, idx)=>{
            switch ( idx % 3){
                case 0: c1.push(card); break;
                case 1: c2.push(card); break;
                case 2: c3.push(card); break;
                default:
            }
        })

        setCol1(c1);
        setCol2(c2);
        setCol3(c3);

        setVuCol1(shuffle(c1));
        setVuCol2(shuffle(c2));
        setVuCol3(shuffle(c3));

        dealHands();
    }

    const doGameTick=(idxSelectedGroup)=>{
        const txtCurrentGameStateName = gameStates[currentGameState];

        //Handlers for exiting a state
        if (txtCurrentGameStateName==='round1'){
            recombineAndDealHands(idxSelectedGroup);
            gotoNextGameState();
        } else if (txtCurrentGameStateName==='round2'){
            recombineAndDealHands(idxSelectedGroup);
            gotoNextGameState();
        } else if (txtCurrentGameStateName==='round3'){
            setWinningGroup(idxSelectedGroup);
            setWinnerOnRoot();
            gotoNextGameState();

        } else if (txtCurrentGameStateName==='winner'){

        }

    }

    const txtCurrentGameStateName = gameStates[currentGameState];
    return (<>
        <div className="logo has-text-centered">
            <figure className={"image is-128x128 is-rounded "}>
                { (txtCurrentGameStateName!=='winner') && <img className="is-rounded" src={imgUptime} alt="Mind reading"/> }
                { (txtCurrentGameStateName==='winner') && <img className="is-rounded" src={imgWin} alt="winner"/> }
            </figure>
            <div className="card is-size-4 is-size-6-mobile has-background-white-tra">
                <span>{ gameCaptions[txtCurrentGameStateName] }</span>
            </div>
        </div>
        <div className={"columns has-text-centered is-multiline is-mobile "}>
            <div className={"column is-4 lfc-card-col " + (( winningGroup===1) ? "winningGroup" : "")}  id="col1">
                <div className="column lh-0">
                    {!winningGroup && <button className="button is-link is-size-7-mobile"
                        disabled={semaphoreDealing!==0}
                        onClick={(e)=>doGameTick(1)}
                    >This group</button>}
                </div>

                {vuCol1.map( ( {icon, suit, myIdx},idx)=>{
                    /* in ClassName,
                            suit: needed to determine identify this as a type of card for layout
                            {Hearts, Diamonds, Clubs, Spades}: needed to determine color to display
                            idx-xx:     identifies the original order,
                                        where idx-3 is the only candidate for winner to be styled accordingly.
                    */
                    return <span key={idx} id={"idx-h1-"+myIdx} className={"deal-start suit " + suit.toLowerCase() + " idx-"+myIdx}>{icon()}</span>;
                })}
            </div>
            <div className={"column is-4 lfc-card-col " + (( winningGroup===2) ? "winningGroup" : "")}  id="col2">
                <div className="column lh-0">
                    {!winningGroup && <button className="button is-info is-size-7-mobile"
                        disabled={semaphoreDealing!==0}
                        onClick={(e)=>doGameTick(2)}
                        >This group</button>}
                </div>
                {vuCol2.map( ( {icon, suit, myIdx},idx)=>{
                    return <span key={idx} id={"idx-h2-"+myIdx} className={"deal-start suit " + suit.toLowerCase() + " idx-"+myIdx}>{icon()}</span>;
                })}
            </div>
            <div className={"column is-4 lfc-card-col " + (( winningGroup===3) ? "winningGroup" : "")}  id="col3">
                <div className="column lh-0">
                    {!winningGroup && <button className="button is-primary is-size-7-mobile"
                        disabled={semaphoreDealing!==0}
                        onClick={(e)=>doGameTick(3)}
                        >This group</button>}
                </div>
                {vuCol3.map( ( {icon, suit, myIdx},idx)=>{
                    return <span key={idx} id={"idx-h3-"+myIdx} className={"deal-start suit " + suit.toLowerCase() + " idx-"+myIdx}>{icon()}</span>;
                })}
            </div>

        </div>

        <button id="btn-dealer"
            onClick={ (e)=>{
                const c1 = 600;
                const c2 = 200;
                for(let i=0; i<7; i++){
                    window.document.getElementById("idx-h1-"+i).classList.remove("deal-to-spot");
                    window.document.getElementById("idx-h2-"+i).classList.remove("deal-to-spot");
                    window.document.getElementById("idx-h3-"+i).classList.remove("deal-to-spot");
                    setSemaphoreDealing(prev=>prev-3);
                    setTimeout( ()=>{
                        const cardSpan = window.document.getElementById("idx-h1-"+vuCol1[i].myIdx);
                        cardSpan.classList.add("deal-to-spot");
                        setSemaphoreDealing(prev=>prev+1);
                    }, i*c1 + c2);
                    setTimeout( ()=>{
                        const cardSpan = window.document.getElementById("idx-h2-"+vuCol2[i].myIdx);
                        cardSpan.classList.add("deal-to-spot");
                        setSemaphoreDealing(prev=>prev+1);
                    }, i*c1 + c2*2);
                    setTimeout( ()=>{
                        const cardSpan = window.document.getElementById("idx-h3-"+vuCol3[i].myIdx);
                        cardSpan.classList.add("deal-to-spot");
                        setSemaphoreDealing(prev=>prev+1);
                    }, i*c1 + c2*3);
                }
            }}
        />

        <div className="columns has-text-centered">
            <div className="column">
                <button id="btn-shuffle" className="button is-warning shuffle" disabled={semaphoreDealing!==0}
                    onClick={(e)=>{

                        for(let i=0; i<7; i++){
                            window.document.getElementById("idx-h1-"+vuCol1[i].myIdx).classList.remove("deal-to-spot");
                            window.document.getElementById("idx-h2-"+vuCol1[i].myIdx).classList.remove("deal-to-spot");
                            window.document.getElementById("idx-h3-"+vuCol1[i].myIdx).classList.remove("deal-to-spot");
                        }

                        setCurrentGameState(0);
                        shuffleDeck();
                        dealHands();
                    }}
                >
                    Shuffle Deck
                </button>

            </div>
        </div>

        {/* Current Game State: {txtCurrentGameStateName} */}

        </>
    )

}